<svg
	xmlns="http://www.w3.org/2000/svg"
	x="0px"
	y="0px"
	viewBox="0 0 18 18"
	xml:space="preserve"
	class={$$props.class || ''}
	><polygon
		points="9,0 11.781,5.596 18,6.493 13.5,10.849 14.562,17 9,14.096 3.438,17 4.5,10.849 0,6.493 6.219,5.596"
		fill="currentColor"
	/></svg
>
